import axios from "axios";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
import cookie from "js-cookie";
import { toNano } from "@ton/core";
import vailcode from "@utils/errcode";
export default {
  name: "popTask",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  data() {
    return {
      amount: 500,
      isSubmit: false
    };
  },
  watch: {
    "$parent.popDepositShow": {
      async handler(newVal, oldVal) {
        if (newVal) {} else {
          this.isSubmit = false;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async onConfirm() {
      if (this.isSubmit) return false;
      this.isSubmit = true;
      let rs = await this.$http.post("/assets/account/deposit", {
        amount: this.amount,
        symbol: this.$parent.itemData.symbol
      });
      this.isSubmit = false;
      vailcode(rs, () => {
        this.$toasted.success('操作完成');
        this.$parent.popDepositShow = false;
      });
    }
  }
};