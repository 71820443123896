/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./popURef.vue?vue&type=template&id=64a7576c&scoped=true"
import script from "./popURef.vue?vue&type=script&lang=js"
export * from "./popURef.vue?vue&type=script&lang=js"
import style0 from "./popURef.vue?vue&type=style&index=0&id=64a7576c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a7576c",
  null
  
)

export default component.exports