import axios from "axios";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
import cookie from "js-cookie";
import vailcode from "@utils/errcode";
export default {
  name: "popTask",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  data() {
    return {
      modifyData: {},
      isUploading: false,
      isSubmit: false
    };
  },
  watch: {
    "$parent.popSysConfigShow": {
      async handler(newVal, oldVal) {
        if (newVal) {
          this.modifyData = Object.assign({}, this.$parent.modifyData);
          switch (this.modifyData.name) {
            case "AirdropTap":
              this.modifyData.value = JSON.parse(this.modifyData.value);
              if (this.modifyData.value.enabled == true) {
                this.modifyData.value.enabled = "1";
              } else {
                this.modifyData.value.enabled = "0";
              }
              break;
          }
        } else {
          this.isSubmit = false;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async onConfirm() {
      if (this.isSubmit) return false;
      this.isSubmit = true;
      let rs = await this.$http.post("/sys/config/modify", this.modifyData);
      this.isSubmit = false;
      vailcode(rs, () => {
        this.$parent.getData();
        this.$parent.popSysConfigShow = false;
      });
    }
  }
};