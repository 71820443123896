var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCreate
    }
  }, [_vm._v("创建新条件")]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("查询中...")] : [_vm._v("查询")]], 2)]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "s icon"
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s url"
    }, [_vm._v(" " + _vm._s(item.tip) + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.condition_type == 'task' ? '扩展任务' : '' + item.condition_type == 'airdrop_task' ? '空投专有任务' : '' + item.condition_type == 'invitation_count' ? '邀请用户数' : '' + item.condition_type == 'mining_earnings' ? '每时挖矿收益' : '') + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.condition_value) + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(_vm.cutStr(item.created_at)) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onModify(item);
        }
      }
    }, [_vm._v("编辑")])])]);
  })], 2), _c('div', {
    staticClass: "data-bd"
  }, [_c('my-pagination', {
    staticClass: "pagination",
    attrs: {
      "total": _vm.total,
      "page-size": _vm.condition.limit,
      "page": _vm.condition.page,
      "on-page-change": _vm.onPageChange,
      "on-page-size-change": _vm.onPageSizeChange
    }
  })], 1), _c('pop-airdrop-condition', {
    attrs: {
      "show": _vm.popAirdropShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm._v(" ID ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 标题 ")]), _c('div', {
    staticClass: "s icon"
  }, [_vm._v(" ICON ")]), _c('div', {
    staticClass: "s url"
  }, [_vm._v(" 提示 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 条件类型 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 条件值 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 添加时间 ")]), _c('div', {
    staticClass: "s opt"
  })]);
}];
export { render, staticRenderFns };