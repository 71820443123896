import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      list: [],
      popAirdropShow: false,
      modifyData: {
        "icon": ""
      },
      isLoading: false,
      selectTab: 0,
      total: 0,
      condition: {
        page: 1,
        limit: 15
      },
      chainList: []
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let rs = await this.$http.post("/airdrop/list", {
        status: this.selectTab == 0 ? 1 : 0,
        ...this.condition
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data.items;
        this.total = rs.data.total;
      }
    },
    async onCreate() {
      this.modifyData = {
        "icon": "",
        "background": "",
        "rules": [],
        'status': "1"
      };
      this.popAirdropShow = true;
    },
    async onModify(item) {
      this.modifyData = item;
      this.popAirdropShow = true;
    },
    onCondition(item) {
      this.$router.push('airdropcondition?id=' + item.id);
    },
    onTask(item) {
      this.$router.push('airdroptask?id=' + item.id);
    },
    cutStr(str) {
      if (str) return str.replace("T", " ").replace(".000Z", "");
    },
    onRefresh() {
      this.getData();
    },
    onPageChange(e) {
      this.condition.page = e;
      this.getData();
    },
    onPageSizeChange(e) {
      this.condition.limit = e;
      this.getData();
    },
    onChange(e) {
      this.condition.chain = e.split("|")[0] || '';
      this.condition.symbol = e.split("|")[1] || '';
    }
  },
  async mounted() {
    let chainList = [];
    let rs = await this.$http.post("/assets/chains", {});
    if (rs.code == 0) {
      chainList = rs.data;
    }
    this.chainList = chainList;
    rs = await this.$http.post("/assets/tokens", {
      chain_id: this.chain_id
    });
    if (rs.code == 0) {
      rs.data.map(item => {
        chainList.map(sitem => {
          if (!sitem.tokens) {
            sitem.tokens = [];
          }
          if (sitem.id == item.chain) {
            sitem.tokens.push(item);
          }
          return sitem;
        });
      });
    }
    this.getData();
  }
};