var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCreate
    }
  }, [_vm._v("创建新任务")])]), _c('div', {
    staticClass: "tabs"
  }, [_c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 0 && 'active',
    on: {
      "click": function ($event) {
        _vm.selectTab = 0;
        _vm.condition.page = 1;
        _vm.onRefresh();
      }
    }
  }, [_vm._v("启用的")]), _c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 1 && 'active',
    on: {
      "click": function ($event) {
        _vm.selectTab = 1;
        _vm.condition.page = 1;
        _vm.onRefresh();
      }
    }
  }, [_vm._v("禁用的")])]), _c('div', {
    staticClass: "search-box btns"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_vm._v(" 代币 "), _c('select', {
    on: {
      "change": function ($event) {
        return _vm.onChange($event.target.value);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("全部")]), _vm._l(_vm.chainList, function (item, index) {
    return _c('optgroup', {
      attrs: {
        "label": item.id
      }
    }, _vm._l(item.tokens, function (sitem, index) {
      return _c('option', {
        domProps: {
          "value": `${sitem.chain}|${sitem.symbol}`
        }
      }, [_vm._v(" " + _vm._s(sitem.symbol) + " ")]);
    }), 0);
  })], 2)]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("查询中...")] : [_vm._v("查询")]], 2)]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "s icon"
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s label"
    }, [item.background ? _c('img', {
      attrs: {
        "src": item.background,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.token) + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.chain) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.amount) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.supply) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.awarded_amount) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.invitation_amount) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.invitation_tip) + " ")]), _c('div', {
      staticClass: "s url"
    }, [_vm._v(" " + _vm._s(item.rules) + " ")]), _c('div', {
      staticClass: "s sort"
    }, [_vm._v(" " + _vm._s(item.sort) + " ")]), _c('div', {
      staticClass: "s status",
      class: `status_${item.status}`
    }, [_vm._v(" " + _vm._s(item.status == 0 ? '禁用' : '启用') + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.condition_count) + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(_vm.cutStr(item.created_at)) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onTask(item);
        }
      }
    }, [_vm._v("任务")]), _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onCondition(item);
        }
      }
    }, [_vm._v("附加条件")]), _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onModify(item);
        }
      }
    }, [_vm._v("编辑")])])]);
  })], 2), _c('div', {
    staticClass: "data-bd"
  }, [_c('my-pagination', {
    staticClass: "pagination",
    attrs: {
      "total": _vm.total,
      "page-size": _vm.condition.limit,
      "page": _vm.condition.page,
      "on-page-change": _vm.onPageChange,
      "on-page-size-change": _vm.onPageSizeChange
    }
  })], 1), _c('pop-airdrop', {
    attrs: {
      "show": _vm.popAirdropShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm._v(" ID ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 标题 ")]), _c('div', {
    staticClass: "s icon"
  }, [_vm._v(" ICON ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 背景 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 空投代币 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 链 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 空投金额 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 空投总量 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 已奖励金额 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 邀请奖励金额 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 邀请奖励提示语 ")]), _c('div', {
    staticClass: "s url"
  }, [_vm._v(" 任务规则 ")]), _c('div', {
    staticClass: "s sort"
  }, [_vm._v(" 排序 ")]), _c('div', {
    staticClass: "s status"
  }, [_vm._v(" 状态 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 条件数 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 添加时间 ")]), _c('div', {
    staticClass: "s opt"
  })]);
}];
export { render, staticRenderFns };